import React from "react";
import i18next from "i18next";

import {authRoles} from "../../auth/index.js";
import en from "./i18n/en.js";

i18next.addResourceBundle("en", "qrcodes", en);

const QRCodesConfig = {
  settings: {layout: {config: {}}},
  auth: authRoles.user,
  routes: [
    {
      path: "/qrcodes",
      exact: true,
      component: React.lazy(() => import("./qrcodes.js")),
    },
  ],
};

export default QRCodesConfig;
