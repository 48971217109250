// eslint-disable-next-line no-shadow
export const Permissions = {
  all: {
    manage: "MANAGE",
    read: "READ",
  },
  photo: {
    manage: "MANAGE_PHOTO",
    read: "READ_PHOTO",
  },
  qrcode: {
    manage: "MANAGE_QRCODE",
    read: "READ_QRCODE",
  },
  settings: {
    manage: "MANAGE_SETTINGS",
    read: "READ_SETTINGS",
  },
  account: {
    manage: "MANAGE_ACCOUNT",
    read: "READ_ACCOUNT",
  },
};

const ManagePhotoPermissions = [
  Permissions.all.manage,
  Permissions.photo.manage,
];

const ReadPhotoPermissions = [
  ...ManagePhotoPermissions,
  Permissions.all.read,
  Permissions.photo.read,
];

const ManageQRCodePermissions = [
  Permissions.all.manage,
  Permissions.qrcode.manage,
];

const ReadQRCodePermissions = [
  ...ManageQRCodePermissions,
  Permissions.all.read,
  Permissions.qrcode.read,
];

const ManageAccountPermissions = [
  Permissions.all.manage,
  Permissions.account.manage,
];

const ReadAccountPermissions = [
  ...ManageAccountPermissions,
  Permissions.all.read,
  Permissions.account.read,
];

const ManageSettingsPermissions = [
  Permissions.all.manage,
  Permissions.settings.manage,
];

const ReadSettingsPermissions = [
  ...ManageSettingsPermissions,
  Permissions.all.read,
];

export const canManagePhoto = userPermissions => {
  if (!userPermissions || !userPermissions.length) {
    return false;
  }

  return userPermissions.some(p => ManagePhotoPermissions.includes(p));
};

export const canReadPhoto = userPermissions => {
  if (!userPermissions || !userPermissions.length) {
    return false;
  }

  return userPermissions.some(p => ReadPhotoPermissions.includes(p));
};

export const canManageQRCode = userPermissions => {
  if (!userPermissions || !userPermissions.length) {
    return false;
  }

  return userPermissions.some(p => ManageQRCodePermissions.includes(p));
};

export const canReadQRCode = userPermissions => {
  if (!userPermissions || !userPermissions.length) {
    return false;
  }

  return userPermissions.some(p => ReadQRCodePermissions.includes(p));
};

export const canManageSettings = userPermissions => {
  if (!userPermissions || !userPermissions.length) {
    return false;
  }

  return userPermissions.some(p => ManageSettingsPermissions.includes(p));
};

export const canReadSettings = userPermissions => {
  if (!userPermissions || !userPermissions.length) {
    return false;
  }

  return userPermissions.some(p => ReadSettingsPermissions.includes(p));
};

export const canManageAccount = userPermissions => {
  if (!userPermissions || !userPermissions.length) {
    return false;
  }

  return userPermissions.some(p => ManageAccountPermissions.includes(p));
};

export const canReadAccount = userPermissions => {
  if (!userPermissions || !userPermissions.length) {
    return false;
  }

  return userPermissions.some(p => ReadAccountPermissions.includes(p));
};
