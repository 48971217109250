import React from "react";
import Provider from "react-redux/es/components/Provider.js";
import {Router} from "react-router-dom";

import _history from "../@history/index.js";
import Authorization from "./auth/authorization.js";
import Layout from "./layout/layout.js";

import AppContext from "./app_context.js";
import routes from "./configs/routes.config.js";
import store from "./store/index.js";
import {Auth} from "./auth/index.js";

import "../styles/app.scss";
import "./i18n/i18n.config.js";

const App = () => (
  <AppContext.Provider
    value={{routes}}
  >
    <Provider store={store}>
      <Auth>
        <Router history={_history}>
          <Authorization>
            <Layout />
          </Authorization>
        </Router>
      </Auth>
    </Provider>
  </AppContext.Provider>
);

App.displayName = "App";

export default App;
