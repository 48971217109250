import * as reduxModule from "redux";
// eslint-disable-next-line no-duplicate-imports
import {applyMiddleware, compose, createStore} from "redux";
import thunk from "redux-thunk";
import createReducer from "./reducers/index.js";

/*
Fix for Firefox redux dev tools extension
https://github.com/zalmoxisus/redux-devtools-instrument/pull/19#issuecomment-400637274
 */
reduxModule.__DO_NOT_USE__ActionTypes.REPLACE = "@@redux/INIT";

// eslint-disable-next-line no-process-env
const composeEnhancers = compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

const store = createStore(createReducer(), enhancer);

store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
  if (store.asyncReducers[key]) {
    return false;
  }
  store.asyncReducers[key] = reducer;
  store.replaceReducer(createReducer(store.asyncReducers));
  return store;
};

export default store;
