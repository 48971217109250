import React from "react";
import i18next from "i18next";
import {Redirect} from "react-router-dom";

import {authRoles} from "../../auth/index.js";
import en from "./i18n/en.js";

i18next.addResourceBundle("en", "settings", en);

const SettingsConfig = {
  settings: {layout: {config: {}}},
  auth: authRoles.user,
  routes: [
    {
      path: "/settings",
      exact: true,
      // eslint-disable-next-line react/display-name
      component: () => <Redirect to="/settings/general" />,
    },
    {
      path: "/settings/general",
      exact: true,
      component: React.lazy(() => import("./general_settings.js")),
    },
    {
      path: "/settings/storage",
      exact: true,
      component: React.lazy(() => import("./storage_settings.js")),
    },
    {
      path: "/settings/ppp-tokens",
      exact: true,
      component: React.lazy(() => import("./ppp_tokens_settings.js")),
    },
    {
      path: "/settings/api-tokens",
      exact: true,
      component: React.lazy(() => import("./api_tokens_settings.js")),
    },
    {
      path: "/settings/scenarios",
      exact: true,
      component: React.lazy(() => import("./scenarios_settings.js")),
    },
    {
      path: "/settings/tracking-data",
      exact: true,
      component: React.lazy(() => import("./tracking_data_settings.js")),
    },
    {
      path: "/settings/webhook",
      exact: true,
      component: React.lazy(() => import("./webhook_settings.js")),
    },
  ],
};

export default SettingsConfig;
