import {getInt} from "../../../../helpers/local_storage.js";

import * as Actions from "../actions/index.js";

const defaultLimit = 10;

const initialState = {
  exporterDialog: {
    open: false,
    layout: "",
  },
  deletionDialog: {
    open: false,
    photo: null,
  },
  filter: {
    qrcodes: [],
    trackingData: [],
  },
  list: {
    query: {
      qrcode: null,
      fromDate: null,
      toDate: null,
      trackingDataIds: [],
    },
    page: 0,
    limit: getInt("limit", defaultLimit),
    total: 0,
    totalPages: 0,
    photos: [],
    scenarios: [],
    loading: false,
  },
};

const photos = (state = initialState, action = {}) => {
  switch (action.type) {
  case Actions.PHOTOS_FILTER_FETCHED: {
    return {...state, filter: action.payload};
  }
  case Actions.PHOTOS_FILTER_SET: {
    return {
      ...state,
      list: {
        ...state.list,
        query: {
          ...state.list.query,
          ...action.payload,
        },
      },
    };
  }
  case Actions.PHOTOS_FETCHED: {
    return {
      ...state,
      list: action.payload,
    };
  }
  case Actions.PHOTOS_LIST_UPDATED: {
    return {
      ...state,
      list: {
        ...state.list,
        ...action.payload,
      },
    };
  }
  case Actions.EXPORTER_DIALOG_UPDATED: {
    return {
      ...state,
      exporterDialog: action.payload,
    };
  }
  case Actions.DELETION_DIALOG_UPDATED: {
    return {
      ...state,
      deletionDialog: action.payload,
    };
  }
  default: {
    return state;
  }
  }
};

export default photos;
