import React from "react";
import i18next from "i18next";

import {authRoles} from "../../auth/index.js";
import en from "./i18n/en.js";

i18next.addResourceBundle("en", "auth", en);

const authConfig = {
  settings: {layout: {config: {}}},
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/login",
      exact: true,
      component: React.lazy(() => import("./login.js")),
    },
    {
      path: "/register",
      exact: true,
      component: React.lazy(() => import("./register.js")),
    },
    {
      path: "/forgot-password",
      exact: true,
      component: React.lazy(() => import("./forgotpassword.js")),
    },
    {
      path: "/reset-password",
      exact: true,
      component: React.lazy(() => import("./resetpassword.js")),
    },
  ],
};

export default authConfig;
