import axios from "axios";

export const PHOTOS_FILTER_FETCHED = "PHOTOS_FILTER_FETCHED";
export const PHOTOS_FILTER_SET = "PHOTOS_FILTER_SET";
export const PHOTOS_FETCHED = "PHOTOS_FETCHED";
export const PHOTOS_LIST_UPDATED = "PHOTOS_LIST_UPDATED";
export const EXPORTER_DIALOG_UPDATED = "EXPORTER_DIALOG_UPDATED";
export const DELETION_DIALOG_UPDATED = "DELETION_DIALOG_UPDATED";

export const fetchFilter = () => dispatch => axios.get("/settings/trackingData")
  .then(trackingData => {
    dispatch({
      type: PHOTOS_FILTER_FETCHED,
      payload: {trackingData: trackingData.data},
    });
  });

export const fetchPhotos = () => async (dispatch, getState) => {
  dispatch({
    type: PHOTOS_LIST_UPDATED,
    payload: {loading: true},
  });

  const {list} = getState().photos.photos;

  const trackingDataIds = list.query.trackingDataIds
    .filter(e => Boolean(e))
    .map(item => item.id);

    // Get limit setting from local storage
  const query = {
    limit: list.limit,
    page: list.page,
    qrcodeId: list.query.qrcode ? list.query.qrcode.id : null,
    trackingDataIds,
    fromDate: list.query.fromDate,
    toDate: list.query.toDate,
  };

  const res = await axios.get("/photos/list", {params: query});
  dispatch({
    type: PHOTOS_FETCHED,
    payload: {...res.data, query: list.query, loading: false},
  });
};

export const setLimit = limit => {
  window.localStorage.setItem("limit", limit);

  return dispatch => {
    dispatch({
      type: PHOTOS_LIST_UPDATED,
      payload: {
        limit,
        page: 0,
      },
    });

    dispatch(fetchPhotos());
  };
};

export const setPage = page => dispatch => {
  dispatch({
    type: PHOTOS_LIST_UPDATED,
    payload: {page},
  });

  dispatch(fetchPhotos());
};

export const setFilter = filter => dispatch => {
  dispatch({
    type: PHOTOS_FILTER_SET,
    payload: filter,
  });

  dispatch(setPage(0));
};

export const openExporterDialog = () => ({
  type: EXPORTER_DIALOG_UPDATED,
  payload: {
    open: true,
    layout: "",
  },
});

export const updateExporterDialog = data => ({
  type: EXPORTER_DIALOG_UPDATED,
  payload: data,
});

export const closeExporterDialog = () => ({
  type: EXPORTER_DIALOG_UPDATED,
  payload: {
    open: false,
    layout: "",
  },
});

export const openDeletionDialog = photo => ({
  type: DELETION_DIALOG_UPDATED,
  payload: {
    open: true,
    photo,
  },
});

export const closeDeletionDialog = () => ({
  type: DELETION_DIALOG_UPDATED,
  payload: {
    open: false,
    photo: null,
  },
});
