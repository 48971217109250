const locale = {
  "Profile": "Profile",
  "Email": "Email",
  "Type": "Type",
  "State": "State",
  "Change password": "Change password",
  "Change new password": "Change new password",
  "Current password": "Current password",
  "Enter current password": "Enter current password",
  "New password": "New password",
  "Enter new password": "Enter new password",
  "Confirm new password": "Confirm new password",
  "Confirm": "Confirm",
  "Cancel": "Cancel",
  "Confirm new password does not match": "Confirm new password does not match",
};

export default locale;
