import axios from "axios";
import jwtService from "../../../services/jwt.js";
import * as UserActions from "./user.action.js";

export const REGISTER_ERROR = "REGISTER_ERROR";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";

export const FORGOTPASSWORD_ERROR = "FORGOTPASSWORD_ERROR";
export const FORGOTPASSWORD_SUCCESS = "FORGOTPASSWORD_SUCCESS";

export const register = ({password, inviteToken}) => dispatch => axios.post(
  "/auth/register",
  {password},
  {headers: {Authorization: `Bearer ${inviteToken}`}},
)
  .then(res => {
    jwtService.setSession(res.data.token);

    const user = {
      data: res.data.account,
      role: "USER",
      redirectUrl: "/",
    };

    dispatch(UserActions.setUserData(user));

    dispatch({type: REGISTER_SUCCESS});
  })
  .catch(error => {
    dispatch({
      type: REGISTER_ERROR,
      payload: error.response ? error.response.data : error,
    });
  });

export const forgotPassword = ({email}) => dispatch => axios.post(
  "/auth/forgotPassword",
  {email},
)
  .then(() => {
    dispatch({type: FORGOTPASSWORD_SUCCESS});
  })
  .catch(error => {
    dispatch({
      type: FORGOTPASSWORD_ERROR,
      payload: error.response ? error.response.data : error,
    });
  });

export const resetPassword = ({password, token}) => dispatch => axios.post(
  "/auth/resetPassword",
  {password},
  {headers: {Authorization: `Bearer ${token}`}},
)
  .then(() => {
    dispatch({type: FORGOTPASSWORD_SUCCESS});
  })
  .catch(error => {
    dispatch({
      type: FORGOTPASSWORD_ERROR,
      payload: error.response ? error.response.data : error,
    });
  });
