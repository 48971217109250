const domain = "https://audit.beta.keeex.me";
// const version = "v1";

const baseURL = `${domain}/admingate`;
const accessTokenKey = "jwt_access_token";

const apiConfig = {
  baseURL,
  accessTokenKey,
};

export default apiConfig;
