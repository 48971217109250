import {combineReducers} from "redux";
import auth from "../../auth/store/reducers/index.js";
import photos from "../../main/photos/store/reducers/index.js";

const createReducer = asyncReducers => combineReducers({
  auth,
  photos,
  ...asyncReducers,
});

export default createReducer;
