import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";

const Loading = props => {
  const [showLoading, setShowLoading] = useState(!props.delay);

  useEffect(() => {
    setShowLoading(true);
  }, []);

  if (!showLoading) {
    return null;
  }

  return (
    <div className="pageloader">
      <div className="is-flex is-justify-content-center p-6">
        <div className="bulma-loader-mixin" />
      </div>
    </div>
  );
};

Loading.propTypes = {delay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])};

Loading.defaultProps = {delay: false};

Loading.displayName = "Loading";

export default Loading;
