import React from "react";
import {Redirect} from "react-router-dom";
import Utils from "../helpers/utils.js";
import appsConfigs from "../main/main.config.js";

const routeConfigs = [
  ...appsConfigs,
];

const routes = [
  ...Utils.generateRoutesFromConfigs(routeConfigs, null),
  // eslint-disable-next-line react/display-name
  {component: () => <Redirect to="/pages/errors/error-404" />},
];

export default routes;
