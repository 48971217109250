export const getInt = (key, defaultValue = 0) => {
  try {
    const value = window.localStorage.getItem(key);
    if (!value) return defaultValue;

    return parseInt(value, 10);
  } catch (error) {
    return defaultValue;
  }
};

export const getString = (key, defaultValue = "") => {
  const value = window.localStorage.getItem(key);
  return value || defaultValue;
};
