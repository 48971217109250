import axios from "axios";
import apiConfig from "../configs/api.config.js";

const apiInst = axios.create({baseURL: apiConfig.baseURL});

export const setToken = token => {
  if (token) {
    apiInst.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete apiInst.defaults.headers.common.Authorization;
  }
};

export const getGeneralSettings = async () => {
  const res = await apiInst.get("/settings/general");

  return res.data;
};

export const setGeneralSettings = async data => {
  const res = await apiInst.put("/settings/general", data);

  return res.data;
};

export const getScenariosSettings = async () => {
  const res = await apiInst.get("/settings/scenarios");

  return res.data;
};

export const setScenariosSettings = async data => {
  const res = await apiInst.put("/settings/scenarios", data);

  return res.data;
};

export const getExportingSettings = async () => {
  const res = await apiInst.get("/settings/exporting");

  return res.data;
};

export const setExportingSettings = async data => {
  const res = await apiInst.put("/settings/exporting", data);

  return res.data;
};

export const getStorageSettings = async () => {
  const res = await apiInst.get("/settings/storage");

  return res.data;
};

export const setStorageSettings = async data => {
  const res = await apiInst.put("/settings/storage", data);

  return res.data;
};

export const getPPPTokensSettings = async () => {
  const res = await apiInst.get("/settings/pppTokens");

  return res.data;
};

export const setPPPTokensSettings = async data => {
  const res = await apiInst.put("/settings/pppTokens", data);

  return res.data;
};

export const getWebhookSettings = async () => {
  const res = await apiInst.get("/settings/webhook");

  return res.data;
};

export const createOrUpdateWebhookSettings = async data => {
  let res = null;
  if (data.id) {
    res = await apiInst.put(`/settings/webhook/${data.id}`, data);
  } else {
    res = await apiInst.post("/settings/webhook", data);
  }

  return res.data;
};

export default apiInst;
