import authConfig from "./auth/auth.config.js";
import homeConfig from "./home/home.config.js";
import photosConfig from "./photos/photos.config.js";
import qrCodesConfig from "./qrcodes/qrcodes.config.js";
import settingsConfig from "./settings/settings.config.js";
import profileConfig from "./profile/profile.config.js";
import usersConfig from "./users/users.config.js";

const mainConfigs = [
  authConfig,
  homeConfig,
  photosConfig,
  qrCodesConfig,
  settingsConfig,
  profileConfig,
  usersConfig,
];

export default mainConfigs;
