import React from "react";
import i18next from "i18next";

import {authRoles} from "../../auth/index.js";
import en from "./i18n/en.js";
import fr from "./i18n/fr.js";

i18next.addResourceBundle("en", "profile", en);
i18next.addResourceBundle("fr", "profile", fr);

const ProfileConfig = {
  settings: {layout: {config: {}}},
  auth: authRoles.user,
  routes: [
    {
      path: "/profile",
      exact: true,
      component: React.lazy(() => import("./profile.js")),
    },
  ],
};

export default ProfileConfig;
