import React from "react";
import {useTranslation} from "react-i18next";
import {Link, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import * as authActions from "../../auth/store/actions/index.js";
import {
  canReadPhoto,
  canReadQRCode,
  canReadSettings,
  canReadAccount,
} from "../../helpers/permission.js";

// eslint-disable-next-line max-lines-per-function
const Menu = () => {
  const {t} = useTranslation("common");
  const _location = useLocation();
  const {pathname} = _location;
  const dispatch = useDispatch();
  const user = useSelector(({auth}) => auth.user);

  if (!user || !user.data || _location.pathname.match("/register")) {
    return null;
  }

  return (
    <div id="menu-left" className="is-hidden-mobile">
      <aside className="menu is-hidden-mobile">
        <ul className="menu-list">
          {
            canReadPhoto(user.data.permissions) && (
              <li>
                <Link
                  className={pathname === "/photos" ? "is-active" : ""}
                  to="/photos"
                >
                  <span className="icon is-medium">
                    <i className="fas fa-images" />
                  </span>
                  <span>{t("Photos")}</span>
                </Link>
              </li>
            )
          }

          {
            canReadQRCode(user.data.permissions) && (
              <li>
                <Link
                  className={pathname === "/qrcodes" ? "is-active" : ""}
                  to="/qrcodes"
                >
                  <span className="icon is-medium">
                    <i className="fas fa-qrcode" />
                  </span>
                  <span>{t("QRCodes")}</span>
                </Link>
              </li>
            )
          }

          {
            canReadSettings(user.data.permissions) && (
              <li>
                <Link
                  className={pathname === "/settings" ? "is-active" : ""}
                  to="/settings"
                >
                  <span className="icon is-medium">
                    <i className="fas fa-cog" />
                  </span>
                  <span>{t("Settings")}</span>
                </Link>
              </li>
            )
          }

          {
            canReadAccount(user.data.permissions) && (
              <li>
                <Link
                  className={pathname === "/users" ? "is-active" : ""}
                  to="/users"
                >
                  <span className="icon is-medium">
                    <i className="fas fa-users" />
                  </span>
                  <span>{t("Users")}</span>
                </Link>
              </li>
            )
          }

          <li>
            <Link
              className={pathname === "/profile" ? "is-active" : ""}
              to="/profile"
            >
              <span className="icon is-medium">
                <i className="fas fa-user" />
              </span>
              <span>{t("Profile")}</span>
            </Link>
          </li>

          <li>
            <Link
              to="/login"
              className="has-text-danger"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => dispatch(authActions.logoutUser())}
            >
              <span className="icon is-medium">
                <i className="fas fa-sign-out-alt" />
              </span>
              <span>{t("Logout")}</span>
            </Link>
          </li>
        </ul>
      </aside>
    </div>
  );
};

Menu.displayName = "Menu";

export default Menu;
