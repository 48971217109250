import React from "react";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

const Navbar = () => {
  const user = useSelector(({auth}) => auth.user);
  const _location = useLocation();

  if (!user || !user.data || _location.pathname.match("/register")) {
    return null;
  }

  const logo = `/logos/${user.data.org.logo}`;

  return (
    <nav
      className="navbar is-fixed-top"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <img src={logo} alt="company logo" />
          </a>
        </div>

        <div className="navbar-menu">
          <div className="navbar-start" />

          {/* <div className="navbar-end">
            <div className="navbar-item">
              <a
                className="has-text-danger"
                href="/admingate/logout"
              >
                <span className="icon is-medium">
                  <i className="fas fa-sign-out-alt" />
                </span>
                {t("Logout")}
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </nav>
  );
};

Navbar.displayName = "Navbar";

export default Navbar;
