import React, {useContext} from "react";
import {renderRoutes} from "react-router-config";
import {ToastContainer} from "react-toastify";
import AppContext from "../app_context.js";

import Suspense from "./components/suspense.js";
import NavBar from "./components/navbar.js";
import Menu from "./components/menu.js";

const Layout = () => {
  const appContext = useContext(AppContext);
  const {routes} = appContext;
  // const _location = useLocation();
  // const {pathname} = _location;
  // const matched = matchRoutes(routes, pathname)[0];

  return (
    <div>
      <NavBar />
      <div className="container-fluid">
        <div className="columns is-gapless">
          <div className="column is-narrow site-menu">
            <Menu />
          </div>
          <div className="column">
            <div className="main-container">
              <Suspense>
                {renderRoutes(routes)}
              </Suspense>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

Layout.displayName = "Layout";

export default React.memo(Layout);
