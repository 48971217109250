const locale = {
  "Profile": "Le profil",
  "Email": "L'email",
  "Type": "Le type de compte",
  "State": "L'etat",
  "Change password": "Changer le mot de passe",
  "Change new password": "changer le nouveau mot de passe",
  "Current password": "Mot de passe actuel",
  "Enter current password": "Entrez le mot de passe actuel",
  "New password": "Nouveau mot de passe",
  "Enter new password": "Entrez le nouveau mot de passe",
  "Confirm new password": "Confirmer le nouveau mot de passe",
  "Confirm": "Confirmer",
  "Cancel": "Annuler",
  "Confirm new password does not match": "Le nouveau mot de passe ne correspond pas",
};

export default locale;
