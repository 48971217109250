/* eslint quote-props: ["error", "consistent"] */

const locale = {
  "Logout": "Logout",

  // Menu
  "Photos": "Photos",
  "QRCodes": "QRCodes",
  "Settings": "Settings",
  "Users": "Users",
  "Profile": "Profile",
};

export default locale;
