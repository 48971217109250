import jwtDecode from "jwt-decode";
import axios from "axios";

import {EventEmitter} from "../helpers/utils.js";
import apiConfig from "../configs/api.config.js";
import authRoles from "../auth/auth_roles.js";
import _history from "../../@history/index.js";

import {setToken} from "./api.js";

axios.defaults.baseURL = apiConfig.baseURL;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.withCredentials = true;

const secondTicks = 1000;

class JwtService extends EventEmitter {
  init() {
    this.handleAuthentication();
  }

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      this.emit("onNoAccessToken");
      return;
    }

    if (this.isAuthTokenValid(accessToken)) {
      this.setSession(accessToken);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
    }
  }

  async signInWithEmailAndPassword(email, password) {
    const res = await axios.post("/auth/login", {email, password});
    if (!res.data.token) throw res.data.error;

    this.setSession(res.data.token);

    const user = {
      data: res.data.account,
      role: authRoles.user,
      redirectUrl: "/",
    };

    return user;
  }

  async signInWithToken() {
    try {
      const res = await axios.get("/users");
      if (!res.data) {
        throw new Error("Failed to login with token.");
      }

      this.setSession(res.data.token);

      return {
        data: res.data.account,
        role: authRoles.user,
      };
    } catch (error) {
      this.logout();
      _history.push("/login");
      throw new Error("Failed to login with token.");
    }
  }

  // eslint-disable-next-line class-methods-use-this
  updateUserData(user) {
    return axios.post("/api/auth/user/update", {user});
  }

  // eslint-disable-next-line class-methods-use-this
  setSession(accessToken) {
    if (accessToken) {
      localStorage.setItem(apiConfig.accessTokenKey, accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem(apiConfig.accessTokenKey);
      delete axios.defaults.headers.common.Authorization;
    }

    setToken(accessToken);
  }

  logout() {
    this.setSession(null);
  }

  // eslint-disable-next-line class-methods-use-this
  isAuthTokenValid(accessToken) {
    if (!accessToken) {
      return false;
    }
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / secondTicks;
    if (decoded.exp < currentTime) {
      _history.push("/login");
      return false;
    }

    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  getAccessToken() {
    return window.localStorage.getItem(apiConfig.accessTokenKey);
  }
}

const instance = new JwtService();

export default instance;
